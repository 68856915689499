const baseUrl = "https://app.thevoyager.io/swap";
//const baseUrl = "https://app.playcent.com";
const configuration = {
  isWidget: true,
  widgetId: 51, // get your unique widget id by contacting us on Telegram
  fromChain: "1",
  toChain: "56",
  fromToken: "0x657b83a0336561c8f64389a6f5ade675c04b0c3b",
  toToken: "0xe9b9c1c38Dab5EAB3B7E2AD295425e89bD8db066",
  dstChains: "137,56",
  dstTokens:
    "0x55d398326f99059fF775485246999027B3197955,0xc2132D05D31c914a87C6611C10748AEb04B58e8F,0xe9b9c1c38Dab5EAB3B7E2AD295425e89bD8db066",
  ctaColor: "#000000",
  textColor: "#fff",
  // backgroundColor: "#101319",
  backgroundColor: "#2c274f",
 // logoURI: "ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aDAI.svg",
  logoURI:'https://playcent.com/image/logo.png'
};

const paramString = new URLSearchParams(configuration).toString();
document.getElementById("widget__iframe").src = `${baseUrl}?${paramString}`;